<!-- 用户编辑弹窗 -->
<template>
  <a-modal
    :width="580"
    :visible="visible"
    :confirm-loading="loading"
    :title="'新增文件分组'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="20" :sm="24" :xs="24">
          <a-form-item label="分组名称:" name="name">
            <a-input
              allow-clear
              placeholder="请输入分组名称"
              v-model:value="form.name"
            />
          </a-form-item>
          <!-- <a-form-item label="分类名称:" name="name">
            <a-input
              allow-clear
              placeholder="请输入分类名称"
              v-model:value="form.name"
            />
          </a-form-item> -->
          <!-- <a-form-item label="状态:" name="status">
            <a-input
              allow-clear
              :maxlength="18"
              placeholder="请输入状态"
              v-model:value="form.status"
            />
          </a-form-item> -->
          <!-- <a-form-item label="状态:" name="status">
            <a-select
                allow-clear
                placeholder="请选择状态"
                v-model:value="form.status"
              >
                <a-select-option
                  v-for="item in stateDict"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
          </a-form-item> -->
          <a-form-item
              label="排序:"
              name="sort"
            >
              <a-input-number
                v-model:value="form.sort"
                :min="0"
                :step="1"
              />
              <div style="color:#999999">数字越小越靠前</div>
            </a-form-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <!-- <a-col>
          <a-upload
            list-type="picture-card"
            v-model:file-list="imgList"
            @preview="handlePreview"
            :customRequest="({ file }) => uploadFile(file, 'imageUrl')"
            :remove="removeFile"
          >
            <div v-if="imgList.length < 1">
              <PlusOutlined :style="{fontSize: '26px'}" />
              <div class="ant-upload-text">图片</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-col> -->
        <!-- <a-col>
          <div @click.stop="openClick">
            <a-upload
            list-type="picture-card"
            v-model:file-list="imgList"
            @preview="handlePreview"
            :remove="removeFile"
            :openFileDialogOnClick="false"
          >
            <div v-if="imgList.length < 1">
              <PlusOutlined :style="{fontSize: '26px'}" />
              <div class="ant-upload-text">背景图</div>
            </div>
          </a-upload>
          </div>
        </a-col> -->
      </a-row>
      
    </a-form>
  </a-modal>
</template>

<script>
import setting from '@/config/setting'
import { PlusOutlined } from '@ant-design/icons-vue'
import * as fileGroupApi from '@/api/goods/fileGroup'

// import * as dictDataApi from '@/api/sys/dictData'

export default {
  name: 'fileGroup',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      imgList: [],
      uploadUrl: setting.uploadUrl,
      // 表单验证规则
      rules: {},
      // 提交状态
      loading: false,
      showEdit: false,
      // 是否是修改
      isUpdate: false,
      previewVisible: false,
      previewImage: '',
      // 状态字典项
      stateDict: [],
      showFile: false
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        // if (this.form.categroyPic) {
        //   this.imgList = [{ url: this.form.categroyPic }]
        // }
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    // this.queryDataStates()
  },
  methods: {
    statusChange(e) {
      this.form.status = e.target.value
    },
    openClick(){
      console.log('打开')
      this.showFile = true
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          fileGroupApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                console.log(res.code)
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 删除图片
    // removeFile(file) {
    //   console.log(file)
    //   this.$http
    //     .delete('/file/remove', {
    //       path: file.url
    //     })
    //     .then((res) => {
    //       if (res.data.code === 0) {
    //         this.form.categroyPic = ''
    //         this.$message.success(res.data.msg)
    //       } else {
    //         this.$message.error(res.data.msg)
    //       }
    //     })
    //     .catch((err) => {
    //       this.$message.error(err)
    //     })
    // },
    // // 上传图片
    // uploadFile(file, name) {
    //   const formData = new FormData()
    //   formData.append('file', file)
    //   const hide = this.$message.loading('上传中..', 0)
    //   this.$http
    //     .post(this.uploadUrl, formData)
    //     .then((res) => {
    //       hide()
    //       if (res.data.code === 0) {
    //         if (name === 'categroyPic') {
    //           this.imgList[0] = {
    //             uid: '-4',
    //             name: 'image.png',
    //             status: 'done',
    //             url: res.data.location
    //           }
    //         }
    //         this.form[name] = res.data.location
    //       } else {
    //         this.$message.error(res.data.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       hide()
    //       this.$message.error(e.message)
    //     })
    //   return false
    // },
    handlePreview(file) {
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    }
    // 状态字典项
    // queryDataStates() {
    //   dictDataApi
    //     .querydictdata('isShow')
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.stateDict = res.data
    //         this.stateDict.map((item) => {
    //           item.dictDataCode = parseInt(item.dictDataCode)
    //         })
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // }
  }
}
</script>

<style scoped>
</style>
