<!-- 用户编辑弹窗 -->
<template>
  <a-modal
    :width="800"
    :visible="visible"
    :confirm-loading="loading"
    :title="'图片库'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-layout style="padding: 10px 0; background: #fff; height: 100%">
      <a-layout-sider width="200" :style="{ background: '#fff' }">
        <!-- <a-menu
          mode="inline"
          v-model:selectedKeys="selectedKeys"
          v-model:openKeys="openKeys"
          style="height: 100%"
          @click="handleClick"
        >
          <div v-for="(item, index) in menus" :key="index">
            <a-menu-item :key="item.key">
              {{ item.name }}
            </a-menu-item>
          </div>
          <div class="add" @click="openEdit">新增分组</div>
        </a-menu> -->
        <div class="menu">
          <div class="list">
            <div
              class="menu-sub"
              v-for="(item, index) in menus"
              :key="index"
              @click="menuClick(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="add" @click="openEdit">新增分组</div>
        </div>
      </a-layout-sider>
      <!-- <a-layout style="padding: 24px; background: #fff"> -->
      <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
        <div class="layoutContent">
          <a-row type="flex" justify="space-between">
            <a-col :span="8">
              <a-input-search
                v-model:value="searchValue"
                placeholder="搜索文件名称"
                style="width: 200px"
                @search="onSearch"
              />
            </a-col>
            <a-col :span="4">
              <a-upload
                v-model:file-list="imgList"
                :multiple="true"
                @preview="handlePreview"
                :customRequest="({ file }) => uploadFile(file, 'url')"
                :remove="removeFile"
                :showUploadList="false"
                @change="uploadChange"
              >
                <a-button>
                  <UploadOutlined />
                  上传
                </a-button>
              </a-upload>
            </a-col>
          </a-row>
          <div class="img">
            <div class="img-sub" v-for="(item, index) in picList" :key="index">
              <img
                alt="example"
                style="width: 102px; height: 102px"
                :src="item.filePath"
              />
              <div class="title">{{ item.fileName }}</div>
            </div>
          </div>
          <!-- <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
          
            
          </a-modal> -->
        </div>
      </a-layout-content>
      <!-- </a-layout> -->
    </a-layout>
    <!-- 编辑 -->
    <FileGroup v-model:visible="showEdit" @done="getFileGroup" />
  </a-modal>
</template>

<script>
import setting from '@/config/setting'
import { UploadOutlined } from '@ant-design/icons-vue'
import * as fileApi from '@/api/goods/file'
import * as fileGroupApi from '@/api/goods/fileGroup'
import FileGroup from './file-group.vue'
// import * as pointsCategoryApi from '@/api/jyyw/pointsCategory'
// import * as dictDataApi from '@/api/sys/dictData'
// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })
// }
export default {
  name: 'File',
  components: {
    UploadOutlined,
    FileGroup
  },
  // emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean
    // 修改回显的数据
    // data: Object
  },
  data() {
    return {
      // 表单数据
      // form: Object.assign({}, this.data),
      imgList: [],
      uploadUrl: setting.uploadUrl,
      // 表单验证规则
      // rules: {},
      // 提交状态
      loading: false,
      showEdit: false,
      // 是否是修改
      isUpdate: false,
      previewVisible: false,
      previewImage: '',
      // 状态字典项
      stateDict: [],
      menus: [
        // {
        //   title: '全部',
        //   key: '1'
        // },
        // {
        //   title: '分组1',
        //   key: '2'
        // },
        // {
        //   title: '未分组',
        //   key: '99'
        // }
      ],
      picList: [
        // {
        //   filePath:
        //     'http://dev.teampoint.cn/api/file/ycform/20230515/3d151ad5e92e4b9a820751b1882dff46.jpg',
        //   fileName: '124234123412412341234'
        // },
        // {
        //   filePath:
        //     'http://dev.teampoint.cn/api/file/ycform/20230515/3d151ad5e92e4b9a820751b1882dff46.jpg',
        //   fileName: '1ss'
        // }
      ]
    }
  },
  mounted() {
    this.getFileData()
    this.getFileGroup()
  },
  methods: {
    uploadChange(e) {
      console.log('uploadChange', e)
    },
    menuClick(item) {
      console.log('item', item)
    },
    openEdit() {
      this.showEdit = true
    },
    getFileData() {
      fileApi
        .page({
          sort: 'createTime',
          order: 'desc',
        })
        .then((res) => {
          if (res.code === 0) {
            console.log('res164--------', res.data)
            // this.picList = res.data
          }
        })
    },
    getFileGroup() {
      fileGroupApi
        .page({
          sort: 'createTime',
          order: 'desc'
        })
        .then((res) => {
          if (res.code === 0) {
            console.log('getFileGroup', res.data)
            this.menus = res.data
            this.menus.unshift(
              {
                name: '全部',
                key: '1'
              },
              {
                name: '未分组',
                key: '999'
              }
            )
          }
        })
    },
    openClick() {
      console.log('打开')
    },
    titleClick(e) {
      console.log('titleClick', e)
    },
    /* 保存编辑 */
    save() {
      // const hide = this.$message.loading('请求中..', 0)
      // fileApi
      //   // .save(Object.assign({}, this.picList))
      //   .save(this.picList)
      //   .then((res) => {
      //     hide()
      //     if (res.code === 0) {
      //       console.log('res194***', res.code)
      //       this.$message.success(res.msg)
      //       this.getFileGroup()
      //       this.updateVisible(false)
      //       this.$emit('done')
      //     } else {
      //       this.$message.error(res.msg)
      //     }
      //   })
      //   .catch((e) => {
      //     hide()
      //     this.$message.error(e.message)
      //   })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 删除图片
    removeFile(file) {
      console.log(file)
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.form.categroyPic = ''
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    // 上传图片
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'url') {
              this.imgList[0] = {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            }
            console.log('211', res.data)
            console.log('212', res.data.location)
            this.getFlie(res.data)

            // this.form[name] = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    getFlie(data) {
      let fileData = data
      let parms = {
        channel: fileData.fileInfo.channel,
        fileId: fileData.fileInfo.fileId,
        domain: fileData.fileInfo.domain,
        fileExt: fileData.fileInfo.fileExt,
        filePath: fileData.location,
        // filePath: res.data.fileInfo.filePath,
        fileName: fileData.fileInfo.fileName,
        fileSize: fileData.fileInfo.fileSize,
        fileType: fileData.fileInfo.fileType,
        groupId: fileData.fileInfo.groupId,
        isDelete: fileData.fileInfo.isDelete,
        isRecycle: fileData.fileInfo.isRecycle,
        storage: fileData.fileInfo.storage,
        storeId: fileData.fileInfo.storeId,
        uploaderId: fileData.fileInfo.uploaderId
      }
      const hide = this.$message.loading('请求中..', 0)
      fileApi
        // .save(Object.assign({}, this.picList))
        .save(parms)
        .then((res) => {
          hide()
          if (res.code === 0) {
            console.log('331***', res.code)
            // this.picList.push({
            //   channel: res.data.fileInfo.channel,
            //   fileId: res.data.fileInfo.fileId,
            //   domain: res.data.fileInfo.domain,
            //   fileExt: res.data.fileInfo.fileExt,
            //   filePath: res.data.location,
            //   // filePath: res.data.fileInfo.filePath,
            //   fileName: res.data.fileInfo.fileName,
            //   fileSize: res.data.fileInfo.fileSize,
            //   fileType: res.data.fileInfo.fileType,
            //   storage: res.data.fileInfo.storage,
            //   groupId: res.data.fileInfo.groupId,
            //   storeId: 0,
            //   uploaderId: 0
            // })
            // console.log('213', this.picList)
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    handlePreview(file) {
      console.log('handlePreview', file)
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    }
    // 状态字典项
    // queryDataStates() {
    //   dictDataApi
    //     .querydictdata('isShow')
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.stateDict = res.data
    //         this.stateDict.map((item) => {
    //           item.dictDataCode = parseInt(item.dictDataCode)
    //         })
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // }
  }
}
</script>

<style lang="less" scoped>
.menu {
  border-right: 1px solid #e6e6e6;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  cursor: pointer;
  padding: 5px 0;
  .list {
    height: 440px;
    .menu-sub {
      // display: flex;
      // flex-direction: column;
      // justify-content: flex-start;
      padding: 5px 0;
    }
  }
  .add {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    // align-items: flex-end;
    padding: 20px 0;
    color: #40a9ff;
    cursor: pointer;
  }
}

.img {
  display: flex;
  // flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0;
  .img-sub {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #d9d9d9;
    .title {
      color: #acacac;
      width: 102px;
      overflow: hidden;
      text-overflow: ellipsis; //省略号
      white-space: nowrap;
    }
  }
  .img-sub:nth-last-of-type(1) {
    margin-right: 0px;
  }
}
</style>
