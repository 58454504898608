import request from '@/utils/request'

// api地址
const api = {
  all: '/yoshop/uploadFile',
  fileId: '/yoshop/uploadFile/',
  save: '/yoshop/uploadFile',
  update: '/yoshop/uploadFile/update',
  delete: '/yoshop/uploadFile/',
  deleteBatch: '/yoshop/uploadFile/batch',
  page: '/yoshop/uploadFile/page'
}

/**
   * 根据id查询
   * @param id id
   * @returns {Promise<Object>}
   */
export function getById(id) {
  return new Promise((resolve, reject) => {
    request.get(api.fileId + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, { params: params }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, { params: params }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加
 * @param params 参数
 * @returns {Promise<>}
 */
export function save(data) {
  return new Promise((resolve, reject) => {
    request.post(api.save, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
